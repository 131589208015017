(function () {
  'use strict';

  /* @ngdoc object
   * @name teams
   * @description
   *
   */
  angular
  .module('neo.home.teams', [
    'ui.router',
    'neo.home.teams.newTeam',
    'neo.home.teams.team'
  ]);
}());
